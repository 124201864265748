import { AxiosError } from 'axios';
import { UUID } from 'uuid-generator-ts';

import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApprovalCode } from 'pages/order-page/ui/steps/model';

import { OrderApi } from 'entities/order';

import { ApprovalCodeKey, XSessionIdKey } from 'shared/contants';
import { Spinner } from 'shared/ui';

import type { StepType } from './ui/steps';
import { Step, Steps } from './ui/steps';

sessionStorage.setItem(XSessionIdKey, UUID.createUUID());
sessionStorage.setItem(ApprovalCodeKey, ApprovalCode.ORDER_SIGN);

export const OrderPage = () => {
  const { orderId = '' } = useParams<{ orderId: string }>();
  const [step, setStep] = useState<StepType | null>(null);

  const { data, isError, error } = useQuery({
    queryKey: [orderId],
    queryFn: () => OrderApi.getProcessingState(orderId),
  });

  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      localStorage.setItem('error', JSON.stringify(error.response!.data));
      setStep(Step.Failed);
      return;
    }
  }, [isError]);

  useEffect(() => {
    switch (data) {
      case 'START':
        setStep(Step.Initial);
        return;
      case 'APPROVED':
        setStep(Step.Initial);
        return;
      case 'AWAITING_DECISION':
        setStep(Step.Processing);
        return;
      case 'REJECTED':
        setStep(Step.Failed);
        return;
    }
  }, [data]);

  return (
    <div className="m-auto flex h-screen min-w-[400px] py-10 md:w-fit">
      <div className="grow-1 grid size-full h-fit min-w-[400px] overflow-auto rounded-2xl bg-white p-8 md:w-fit">
        {!data && !isError && <Spinner />}
        {data && step === Step.Initial && <Steps.Initial setStep={setStep} />}
        {data && step === Step.Confirmation && (
          <Steps.Confirmation setStep={setStep} />
        )}
        {data && step === Step.Credentials && (
          <Steps.Credentials setStep={setStep} />
        )}
        {data && step === Step.Processing && (
          <Steps.Processing setStep={setStep} />
        )}
        {data && step === Step.Failed && <Steps.Failed />}
        {data && step === Step.Success && <Steps.Success />}
      </div>
    </div>
  );
};
