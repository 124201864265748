import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import type { StepType } from 'pages/order-page/ui';
import { Step } from 'pages/order-page/ui';

import { OrderApi } from 'entities/order';

import { Spinner } from 'shared/ui';

export const StepProcessing = (props: {
  setStep: (step: StepType) => void;
}) => {
  const { orderId = '' } = useParams<{ orderId: string }>();

  const {
    data: status,
    isError: isStatusError,
    error: statusError,
  } = useQuery({
    queryKey: ['checkAwaitingStatus'],
    queryFn: () => OrderApi.checkAwaitingStatus(orderId),
  });

  useEffect(() => {
    if (isStatusError) {
      localStorage.setItem(
        'error',
        JSON.stringify({
          errorCode: 99,
          // @ts-ignore
          errorMessage: statusError?.response.data.message,
        }),
      );
      props.setStep(Step.Failed);
      return;
    }
  }, [status, isStatusError]);

  return (
    <>
      <p className=" my-4 text-center text-xl font-normal md:text-3xl">
        Ожидаем решения по заявке
      </p>
      <div className="relative mx-auto size-[168px] rounded-full bg-[#D9D9D9]">
        <Spinner className="absolute inset-0" />
      </div>
      <p className="mx-auto my-4 max-w-[80%] text-center text-xl font-normal leading-[120%] md:text-2xl">
        Рассмотрение заявки, пожалуйста, подождите. <br /> <br />
        Ваш заказ автоматически оформится в случае положительного решения
      </p>
    </>
  );
};
