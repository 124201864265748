import { cx } from '@emotion/css';

import type { DatePickerProps } from 'antd';
import { ConfigProvider, DatePicker } from 'antd';
import locale from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

export const DatepickerSimple = ({ defaultValue }: DatePickerProps) => {
  return (
    <ConfigProvider locale={locale}>
      <DatePicker
        className={cx(
          'w-full py-1.5 px-3 text-sm/6 rounded-lg border border-[#575757] flex items-center text-black h-14 hover:border-brand-1',
          'focus:outline-none',
        )}
        defaultValue={
          !!defaultValue &&
          dayjs(
            (defaultValue as unknown as string).split('.').reverse().join('-'),
          )
        }
        suffixIcon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z"
              stroke="#C4C6C8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.75 1.875V4.375"
              stroke="#C4C6C8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.25 1.875V4.375"
              stroke="#C4C6C8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.125 6.875H16.875"
              stroke="#C4C6C8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        onChange={(_, data) => console.log(data)}
      />
    </ConfigProvider>
  );
};
