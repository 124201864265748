import { StepButton } from 'pages/order-page/ui';

export const StepSuccess = () => {
  return (
    <>
      <p className="my-4 text-center text-xl font-normal md:text-3xl">
        Спасибо за покупку!
      </p>
      <div className="mx-auto size-[168px] rounded-full bg-[#D9D9D9]" />
      <p className="mx-auto my-4 max-w-[80%] text-center text-xl font-normal leading-[120%] md:text-2xl">
        Ваш заказ 124125 принят и находится в обработке. Мы сообщим о его
        готовности
      </p>
      <StepButton>Перейти к заказам</StepButton>
    </>
  );
};
