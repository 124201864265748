import { StepConfirmation } from './step-confirmation';
import { StepCredentials } from './step-credentials';
import { StepFailed } from './step-failed';
import { StepInitial } from './step-initial';
import { StepProcessing } from './step-processing';
import { StepSuccess } from './step-success';

export const Steps = {
  Initial: StepInitial,
  Processing: StepProcessing,
  Credentials: StepCredentials,
  Confirmation: StepConfirmation,
  Failed: StepFailed,
  Success: StepSuccess,
};
