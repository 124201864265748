import { StepButton } from 'pages/order-page/ui/step-button';

export const StepFailed = () => {
  const { errorMessage }: { errorCode: number; errorMessage: string } =
    localStorage.getItem('error') && JSON.parse(localStorage.getItem('error')!);

  return (
    <>
      <p className="my-4 text-center text-xl font-normal md:text-3xl">
        {errorMessage ? 'Ошибка' : 'Отказ'}
      </p>
      <div className="mx-auto size-[168px] rounded-full bg-[#D9D9D9]" />
      <p className="mx-auto my-4 max-w-[80%] text-center text-xl font-normal leading-[120%] md:text-2xl">
        {errorMessage
          ? errorMessage
          : 'К сожалению, мы не можем утвердить вашу заявку'}
      </p>
      {errorMessage ? (
        <a
          href="/"
          className="mx-auto size-fit gap-2  self-end rounded-2xl bg-black py-4 text-white flex-center md:w-56"
        >
          На главную
        </a>
      ) : (
        <StepButton>К оплате</StepButton>
      )}
    </>
  );
};
