import './styles/main.scss';

import { OrderPage } from 'pages';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { RoutesPath } from 'shared/routes-path';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {/*<Route path="/" element={<MainPage />} />*/}
          <Route path={RoutesPath.ORDER} element={<OrderPage />} />
          <Route
            path="*"
            element={
              <Navigate
                replace
                to="/order-request/0ea3d776-dadc-4d38-ac94-ed847e478ec7"
              />
            }
          />
        </Routes>
        <ToastContainer closeOnClick theme="colored" />
      </BrowserRouter>
    </QueryClientProvider>
  );
};
