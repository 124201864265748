import { cx } from '@emotion/css';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InferType } from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Input, Label } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import type { StepType } from 'pages/order-page/ui';
import { Step } from 'pages/order-page/ui';
import { StepButton } from 'pages/order-page/ui/step-button';

import { onAxiosError } from 'shared/lib';

const schema = yup.object({
  first_name: yup.string().trim().min(4).required(),
  mid_name: yup.string().trim().min(4).required(),
  last_name: yup.string().trim().min(4).required(),
  birthday: yup.string().trim().min(4).required(),
  email: yup.string().trim().required(),
  phone: yup.string().trim().min(4).required(),
});

const date = new Date();

export const StepCredentials = (props: {
  setStep: (step: StepType) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const sendMutation = useMutation({
    // @ts-ignore
    mutationFn: (data: InferType<typeof schema>) => console.log(data),
    onError: onAxiosError,
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    sendMutation.mutate(data);
  };

  return (
    <>
      <p className="my-4 text-justify text-xl font-normal md:text-2xl">
        Введите данные карты
        <p className="pt-2 text-xs text-gray-400">
          С этой карты будут списываться платежи
        </p>
      </p>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          {['opacity-100', 'opacity-75', 'opacity-50', 'opacity-25'].map(
            (opacity, index) => (
              <span className="flex w-full flex-col gap-1">
                <span
                  className={cx(`bg-brand-1 h-2 rounded-3xl w-full ${opacity}`)}
                />
                {!index && <small>Сегодня</small>}
                {!!index && (
                  <small>
                    {date.getDay()}.{date.getMonth() + index}.
                    {date.getFullYear()}
                  </small>
                )}
                650 ₽
              </span>
            ),
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div className="my-4">
          <Field className="h-16">
            <InputMask
              mask="9999 9999 9999 9999"
              {...register('phone')}
              className={cx(
                'flex items-center rounded-lg border border-wh border-[#575757] text-[#727272] bg-transparent py-1.5 px-3 text-sm/6 h-14 w-full',
                'focus:outline-none',
              )}
            >
              {(props: any) => <Input {...props} placeholder="Номер карты" />}
            </InputMask>
          </Field>
          <Field className="h-16">
            <Label className="hidden text-sm/6 font-medium text-white">
              Имя держателя
            </Label>
            <Input
              {...register('first_name')}
              placeholder="Имя"
              className={cx(
                'w-full rounded-lg border border-[#575757] flex items-center h-14 hover:border-brand-1 py-1.5 px-3 text-sm',
                'focus:outline-none',
              )}
            />
          </Field>
          <div className="flex gap-2">
            <Field className="h-16 w-full">
              <InputMask
                mask="99/99"
                {...register('phone')}
                className={cx(
                  'flex items-center rounded-lg border border-wh border-[#575757] text-[#727272] bg-transparent py-1.5 px-3 text-sm/6 h-14 w-full',
                  'focus:outline-none',
                )}
              >
                {(props: any) => <Input {...props} placeholder="Месяц/год" />}
              </InputMask>
            </Field>
            <Field className="h-16 w-full">
              <InputMask
                mask="999"
                {...register('phone')}
                className={cx(
                  'flex items-center rounded-lg border border-wh border-[#575757] text-[#727272] bg-transparent py-1.5 px-3 text-sm/6 h-14 w-full',
                  'focus:outline-none',
                )}
              >
                {(props: any) => <Input {...props} placeholder="CVC/CVV" />}
              </InputMask>
            </Field>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <span className="rounded border border-brand-1">
              <FontAwesomeIcon
                icon={faCheck}
                className=" size-4 text-brand-1 group-data-[selected]:visible"
              />
            </span>
            <p>
              Даю согласие на&nbsp;
              <a href="/frontend/fe-service/public" className="underline">
                соглашение об использовании аналога собственноручной подписи
              </a>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <span className="rounded border border-brand-1">
              <FontAwesomeIcon
                icon={faCheck}
                className=" size-4 text-brand-1 group-data-[selected]:visible"
              />
            </span>
            <p>
              Я ознакомился и даю согласие с&nbsp;
              <a href="/frontend/fe-service/public" className="underline">
                договором-офертой
              </a>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <span className="rounded border border-brand-1">
              <FontAwesomeIcon
                icon={faCheck}
                className=" size-4 text-brand-1 group-data-[selected]:visible"
              />
            </span>
            <p>
              Даю согласие на&nbsp;
              <a href="/frontend/fe-service/public" className="underline">
                обработку персональных данных Финмолл
              </a>
            </p>
          </div>
        </div>
        <StepButton
          onClick={() => {
            props.setStep(Step.Processing);
          }}
        >
          Оплатить
        </StepButton>
      </form>
      <div className="mt-3 flex flex-col gap-1">
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="text-sm/6 font-medium text-rose-400">
              {message}
            </span>
          ))}
      </div>
    </>
  );
};
