import type { OrderRequestResponse } from 'entities/order/model/order';

import { axiosInstance } from 'shared/api/axios.defaults';
import { delay } from 'shared/lib';

type Status = 'START' | 'AWAITING_DECISION' | 'REJECTED' | 'APPROVED';

const getXOrderLink = (orderId: string) =>
    `${location?.protocol}//${location?.hostname}${location?.port ? `:${location.port}` : ''}/order-request/${orderId}`;

export class OrderApi {
  static readonly KEY = 'card';

  static async getProcessingState(orderId: string): Promise<Status> {
    try {
      const { data } = await axiosInstance.post(
        '/unsecured/order-request/processing-stage',
        {
          orderRequestLink: getXOrderLink(orderId),
        },
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  static async checkRepeatedClient(orderId: string) {
    try {
      const { data } = await axiosInstance.get(
        '/secured/person/repeated-client',
        {
          headers: {
            'X-Order-Link': getXOrderLink(orderId),
          },
        },
      );

      if (!data) {
        return this.orderRequest(orderId);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }

  static async orderRequest(orderId: string): Promise<OrderRequestResponse> {
    try {
      const { data } = await axiosInstance.get('/secured/order-request', {
        headers: {
          'X-Order-Link': getXOrderLink(orderId),
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async sendSms(orderId: string, approval_code_type: string) {
    try {
      const { data } = await axiosInstance.post(
        'secured/sms/send-sms',
        {
          approval_code_type,
        },
        {
          headers: {
            'X-Order-Link': getXOrderLink(orderId),
          },
        },
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  static async checkSms({
    orderId,
    code,
    approval_code_type,
  }: {
    orderId: string;
    code: string;
    approval_code_type: string;
  }): Promise<{ success: boolean }> {
    try {
      const { data } = await axiosInstance.post(
        'secured/sms/check-sms',
        {
          approval_code_type,
          code,
        },
        {
          headers: {
            'X-Order-Link': getXOrderLink(orderId),
          },
        },
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  static async enrichment(props: {
    orderId: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    birth_date: string;
    email: string;
  }) {
    try {
      const { data } = await axiosInstance.post(
        'secured/order-request/enrichment',
        {
          first_name: props.first_name,
          last_name: props.last_name,
          middle_name: props.middle_name,
          birth_date: props.birth_date,
          email: props.email,
        },
        {
          headers: {
            'X-Order-Link': getXOrderLink(props.orderId),
          },
        },
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async checkAwaitingStatus(
    orderId: string,
    startTime: number = Date.now(),
  ): Promise<Status> {
    try {
      const { data } = await axiosInstance.post(
        '/secured/order-request/check-awaiting-status',
        {},
        {
          headers: {
            'X-Order-Link': getXOrderLink(orderId),
          },
        },
      );

      if (data === 'AWAITING_DECISION') {
        const elapsedTime = Date.now() - startTime;

        if (elapsedTime > 600000) {
          throw new Error(
            'Решение не было получено. Пожалуйста, обновите страницу.',
          );
        }

        await delay(5000);
        return this.checkAwaitingStatus(orderId, startTime);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
}
