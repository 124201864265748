import { cx } from '@emotion/css';

import type { HTMLAttributes } from 'react';

export const StepButton = ({
  children,
  className,
  onClick,
  disabled,
}: HTMLAttributes<HTMLButtonElement> & { disabled?: boolean }) => {
  return (
    <button
      disabled={disabled}
      className={cx(
        'mx-auto size-fit gap-2 self-end rounded-2xl bg-black py-4 text-white flex-center md:w-56',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
